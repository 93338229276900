import React from 'react'

const HomePage = (props) => {
  if (typeof window === "undefined") return null

  return <div>
    Temporary home
  </div>
}

export default HomePage
